.maincontainer {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-y: overlay;
  background-color: #131e27;
  .stheader {
    height: 97.5px;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #203441;
    display: flex;
    flex-direction: row;
    .logo {
      height: 18px;
      margin: 45px 0 0 32px;
      object-fit: contain;
    }
    .headerSubtitle {
      color: #ffffff;
      font-size: 16px;
      height: 16px;
      margin: 45px 0 0 29px;
      
      font-weight: light;
      letter-spacing: 0;
    }
    .support-container {
      display: flex;
      margin-top: 45px;
      flex: 1;
      justify-content: flex-end;
      .support-text {
        color: #ffffff;
        font-size: 16px;
        height: 16px;
        text-decoration: underline;
        margin-right: 32px;
        
        font-weight: normal;
      }
      .support-icon {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }
    }
  }
}
.support-title {
  
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 0;
}
.support-subtitle {
  
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #d1334c;
  margin: 0;
}
.support-text {
  
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #131e27;
  margin: 0;
}
.support-popup-show{
  height: 40%;
  width: 250px;
  word-break: break-word;
  position: fixed;
  left: 200px;
  top: 40%;
  transition: right 0.50s ease-in;
  box-shadow: 0px 1px 20px rgb(238, 237, 237);
  background-color: #e3bfb3 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;

}
.support-popup-hide{
  height: 40%;
  width: 300px;
  position: fixed;
  left: -300px;
  top: 40%;
  transition: right 0.50s ease-out;
  // box-shadow: 0px 1px 20px;
  background-color: #e3bfb3 !important;

}

.support-content {
  justify-content: center;
  width: 224px;
  height: 194px;
  position: absolute !important;
  right: 0;
  margin-right: 25px;
  background-color: #e3bfb3 !important;
  padding-left: 29px !important;
  padding-top: 10px !important;
}
.support-content-fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 200ms, opacity 200ms;
}
.support-content-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 200ms, opacity 200ms;
}
.support-content p.title {
  
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 0;
}
.support-content p.subtitle {
  
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #d1334c;
  margin: 0;
}
.support-content p.text {
  
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #131e27;
  margin: 0;
}
.keep-login-container {
  margin-top: 20px;
}
.keep-login-container .uncheck {
  display: inline-block;
}
.keep-login-container .check {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: #131e27;
  .center-content {
    display: flex;
    min-height: 80vh;
    flex-direction: row;
    .content-left {
      display: flex;
      flex: 1;
      margin-top: 50px;
      flex-direction: column;
      .content-image {
        height: 460px;
        width: 500px;
        align-self: center;
      }
      .menu-card-content {
        display: none;
      }
      .content-header-mobile {
        display: none;
      }
    }
    .content-right {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 4rem;
      .content-right form {
        display: flex;
        flex-direction: column;
      }
      .content-header {
        font-size: 32px;
        color: #cf2948;
        font-weight: 300;
        max-width: 31rem;
        text-align: left;
        
      }
      .content-subtitle {
        color: #c8d6e0;
        font-size: 16px;
        height: 14px;
        text-align: left;
        font-weight: bold;
        margin-top: 20px;
      }
      .content-subtitle-new-pass {
        color: #c8d6e0;
        font-size: 20px;
        height: 14px;
        text-align: left;
        font-weight: bold;
        margin-top: 20px;
      }
      .login-error-text {
        width: 263px;
        color: #ff6060;
        font-size: 12px;
        
        text-align: right;
      }
      .reset-success-text {
        width: 263px;
        color: #33d16a;
        font-size: 12px;
        
        text-align: left;
        margin-top: 9px;
      }
      .login-input {
        background-color: #131e27;
        width: 263px;
        height: 40px;
        border: 1px solid #203441;
        border-radius: 5px;
        margin: 16px 0 0 0;
        padding-left: 16px;
        
        color: #ffffff;
      }
      .login-visible {
        position: absolute;
        right: 5px;
        bottom: 10px;
      }
      .login-visible-off {
        position: absolute;
        right: 5px;
        bottom: 10px;
      }
      .login-input:focus {
        border: 1px solid #203441;
      }
      .login-input:hover {
        border: 1px solid #ffffff;
      }
      .login-input ::placeholder {
        color: #455c6c;
        font-size: 16px;
        
      }
      .login-input:hover::placeholder {
        color: #ffffff;
      }

      .login-button {
        background: #cf2948 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        height: 40px;
        width: 263px;
        border: none;
        color: white;
        margin-top: 16px;
        
      }
      .login-button:hover {
        background-color: #ac1d37;
      }
      .signup-container {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        color: #ffffff;
        font-size: 16px;
        height: 16px;
        text-decoration: underline;
        margin-right: 32px;
        width: 263px;
        
      }
    }
  }
  .loginfooter {
    display: flex;
    flex-direction: row;
    bottom: 15px;
    padding-bottom: 10px;
    height: 8%;
    color: #455c6c;
    font-size: 14px;
    margin-left: 90px;
    
  }
}
.cursor {
  cursor: pointer;
}
.menu-button {
  display: none;
}
.headerSubtitle-mobile {
  display: none;
}

.unsubscribeLabels{
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-left: 8px,
}

.unsubscribeLoader{
  margin-left: 15%;
  margin-top: 15%;
}

@media (max-width: 800px) {
  .maincontainer {
    overflow-y: scroll;
    .stheader {
      .logo {
        height: 29px;
        width: 158px;
        align-self: center;
        margin: auto;
      }
      .menu-button {
        display: inline-block;
        height: 24px;
        width: 24px;
        align-self: center;
        margin-right: 16px;
      }
      .headerSubtitle {
        display: none;
      }
      .support-container {
        display: none;
      }
    }
    .content {
      height: fit-content;
      .center-content {
        display: block;
        .content-left {
          display: grid;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          align-items: center;
          margin-top: 0px;
          .content-image {
            height: 252px;
            width: 302px;
            align-self: center;
          }
          .content-header-mobile {
            display: block;
            font-size: 22px;
            color: #cf2948;
            width: 304px;
            height: 84px;
            font-weight: 300;
            text-align: center;
            
            align-self: center;
            margin-top: 14px;
          }
          .headerSubtitle-mobile {
            display: block;
            color: #ffffff;
            font-size: 16px;
            height: 16px;
            
            font-weight: normal;
            letter-spacing: 0;
            text-align: center;
            margin-top: 36px;
          }
          .menu-card-content {
            display: block;
            width: 100vw;
            background-color: #e3bfb3;
            position: absolute;
            padding: 24px;
            .menu-title {
              font-weight: bold;
              color: #182631;
              font-size: 23px;
            }
            .divider {
              border-bottom: 1px;
              border-bottom-color: #253c4b;
              border-bottom-style: solid;
              margin: 15px 0;
            }
            .menu-content {
              .support-logo {
                height: 40px;
                width: 40px;
              }
              & > div > div {
                .menu-subtitle {
                  font-weight: bold;
                  font-size: 14px;
                  text-align: left;
                  color: #d1334c;
                  margin: 0;
                  width: 80px;
                }
                .menu-text {
                  font-weight: 600;
                  font-size: 14px;
                  text-align: left;
                  color: #131e27;
                  margin: 0;
                }
              }
            }
          }
          .menu-card-content-slide-down {
            height: 489px;
            opacity: 1;
            position: absolute;
            transition: opacity 300ms, height 300ms;
          }
          .menu-card-content-slide-up {
            display: none;
            height: 489px;
            width: 100vw;
            opacity: 0;
            transition: opacity 300ms, height 300ms;
          }
        }
        .content-right {
          display: grid;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          margin-top: 20px;
          .content-header {
            display: none;
          }
          & > div {
            .signup-container {
              width: 302px;
              margin-right: 0px;
            }
            .content-subtitle {
              text-align: center;
            }
            & > form {
              .login-input {
                width: 302px;
                height: 40px;
              }
              .login-button {
                width: 302px;
              }
            }
            .content-subtitle-new-pass {
              text-align: center;
            }
          }
        }
      }
      .loginfooter {
        display: none;
      }
    }
  }
}

.languageSelectInput {
    .modal-content {
      margin: .6rem;
    }
}

@media (max-width: 991px) {
  .support-popup-show{
    left: auto;
    right: 200px !important;
  }
}