.label-class {
  color: #6489a0 !important;
  text-transform: uppercase;
  opacity: 1;
  text-align: left;
  font-size: 12px;
  font-weight: 500 !important;
  
}

.submit-button {
  width: 150px !important;
  height: 40px !important;
  background-color: #d1334c !important;
  border-radius: 20px !important;
  opacity: 1 !important;
  border: 1px solid !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-top: 20px;
  margin-left: 40px;
}
