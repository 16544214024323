.spinner {
  height: 100vh; //vh - viewport height
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
  .image {
    vertical-align: middle;
    -webkit-animation: none;
  }
  .box {
    vertical-align: middle;
  }
}
.margin-loader {
  margin-left: 400px;
}
