.user-profile-text {
  font-weight: bold;
  font-size: 16px;
  
  text-transform: uppercase;
  margin-left: 10px;
}
.back-arrow {
  width: 24px;
  height: 24px;
  background: transparent;
  opacity: 1;
  cursor: pointer;
}
.back-arrow-span {
  
  font-size: 16px;
  color: #131e27;
  opacity: 1;
}
.spinner-class {
  width: 22px;
  height: 22px;
}
.spinnerPosition {
  margin-left: 40%;
}
.horizontal-line {
  width: 2px;
  height: 12px;
  background-color: #dae6ee;
  /* align-self: center; */
  margin-left: 15px;
}
.full-user-name {
  margin-left: 15px;
  font-weight: normal;
  font-size: 16px;
  color: #131e27;
  
}
.close-text {
  color: #d1334c;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  
  cursor: pointer;
}
.modal-header {
  border: none !important;
}
.modal-footer {
  border: none !important;
}
.save_button {
  width: 180px;
  height: 40px;
  background-color: #cf2948 !important;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  
  font-weight: bold;
  text-align: center;
  cursor: pointer !important;
}
.discard_button {
  width: 180px;
  height: 40px;
  border: 2px solid #cf2948 !important;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  color: #cf2948 !important;
  font-size: 14px;
  
  font-weight: bold;
  text-align: center;
  background-color: transparent !important;
  margin-right: 20px;
}
.confirm_button {
  width: 180px;
  height: 40px;
  background-color: #cf2948 !important;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  
  font-weight: bold;
  text-align: center;
  cursor: pointer !important;
}
