.companyProductCount {
    margin: 2px;
    height: 10rem;
    width: 13rem !important;
    opacity: 1px;
    background-color: #ffffff;
    padding: 5px 5px 7px 5px;
    border-style: solid;
    border-width: 1.5px;
    border-color: #DFEDF5;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    text-align: center;

    &__data {
        font-size: 2.6rem;
        width: 100%;
    }

    &__data[data-negativeBalance="true"] {
        color: red;
    }

    &__info {
        font-size: 1.3rem;
        width: 100%;
    }
}

.billing-info {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
    
    .MuiPaper-root {
        max-height: 32rem;
        overflow: scroll;
    }
}

.mydatePicker{
    width: 420px !important;
}

.contentCreatedDatePicker .react-datepicker-wrapper{
    width: 100%;
}
