/* In this file GLOBAL SASS File added */


@import "./vendor/vendor.scss";

@import "../fonts/fonts.scss";

@import "./abstract/variables";
@import "./abstract/functions";

/* === Icon fonts === */
@import "./mixins/misc";


/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";
@import "~font-awesome/scss/font-awesome";
@import "~flag-icon-css/sass/flag-icon";
@import "~simple-line-icons/scss/simple-line-icons";
@import "~simple-line-icons/scss/simple-line-icons";

/* === Template mixins === */
@import "./mixins/animation";




@import "./layout/navbar";
@import "./layout/sidebar";
@import "./layout/formFieldSection";

// =============== components ===============
@import "./components/ContentCard";
@import "./components/divider";
@import "./components/forms";
@import "./components/titles";
@import "./components/badges";
@import "./components/buttons";
@import "./components/langTab";
@import "./components/profile-tab";
@import "./components/CountCard";
@import "./components/ProductCard";
@import "./components/UpidsProductCard";
@import "./components/Users";
@import "./components/LoginLayout";
@import "./components/ManufacturerSideBar";
@import "./components/UpidsForm";
@import "./components/ProductSideBar";
@import "./components/Spinner";
@import "./components/UserProfileNavbar";
@import "./components/Product";
@import "./components/TableView";
@import "./components/ClickstreamTable";
@import "./components/MediaCard";
@import "./components/contentfulData";
@import "./components/Payment";
@import "./components/company";
@import "./components/modal";


@import "./components/inputColorWithLabel";
@import "./components/InputDateTimePicker";
@import "./components/inputMultiSelectWithModal";
@import "./components/inputSelectDropdown";
@import "./components/inputSwitchWithLabel";
@import "./components/inputTextAreaWithLabel";
@import "./components/inputTextWithLabel";
@import "./components/inputTextMultiAddWithLabel";
@import "./components/inputUrlMultiAddWithModal";
@import "./components/loadingOverlay";
@import "./components/media3dModelViewer";
@import "./components/mediaImage";
@import "./components/mediaVideo.scss";


//===================== Pages =================
@import "./pages/addProduct";
@import "./pages/dashboard";
@import "./pages/productProfile";
@import "./pages/users";
@import "./pages/manufacture";
@import "./pages/contentManagement";


body {
  font-family: $fontFamily !important;
}

.ui {
  font-family: $fontFamily !important;
}

body ::-webkit-scrollbar {
  width: 0px;
}

body {
  overflow-x: unset !important;
  min-width: auto !important;
}

.container-scroller {
  background-color: #eff8fd;
  padding-top: 72px;

  .container-fluid {
    padding-left: 0px !important;

    .main-panel {
      margin-left: 190px;
      background-color: white;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@media screen and (max-width: 991px) {
  .main-panel {
    margin-left: 0px !important;
  }
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.form-group {
  margin-bottom: 1rem;
}

.accordion-item {
  border: none !important;
  box-shadow: none !important;
}

.ui.search.dropdown>.text {
  top: -5px !important;
}

.ui.search.dropdown.analytics-dropdown>.text {
  top: 0px !important;
}

.ui.multiple.search.dropdown>.text {
  top: 0px !important
}

.lineGraphContainerStyle {
  height: 580px;
  width: 97% !important;
  background: rgb(216, 243, 252);
}

.addupids-product .default.text {
  color: #253c4b !important;
  /* Change 'red' to the color you want */
}