.textAreaWithLabel {
    &__inputwrapper {


        &__input {
            
            background-color: #F8FCFD;
            border-radius: 7px;
            height: 170px;
            font-size: 12px;
            padding: 16px;
            width: 100%;
            color: #253C4B;
            outline: none;
        }

        &__aiIcon {
            position: absolute;
            cursor: pointer;
            right: .5rem;
            top: .5rem;
            font-size: 1.5rem;
            height: 1.5rem;
        }
    }
}