.mediaImage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto !important;
    padding: 1rem;
}

.mediaImageOther {
    &__upload {
        position: absolute;
        right: 2rem;
        margin-top: -2.5rem;
    }

    &__images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: auto !important;

    }
}