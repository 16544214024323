.inputTextWithLabel {
    margin-top: .6rem;
    margin-bottom: .6rem;

    &__label {
        max-width: 100%;
        display: flex;

        label {
            display: block;
            margin-bottom: .5rem;
            color: #6489a0;
            text-transform: uppercase;
        }
    }


    &__inputwrapper {


        &__input {
            background-color: #f8fcfd;
            border-radius: 7px;
            border: 1px solid #ededed;
            height: 40px;
            font-size: 12px;
            padding: 16px;
            width: 100%;
            color: #253c4b;
            outline: none;
        }

        &__input:read-only {
            background-color: #f8fcfd;
        }

        &__aiIcon {
            position: absolute;
            cursor: pointer;
            right: 1rem;
            top: 2.5rem;
            font-size: 1.5rem;
            height: 1.5rem;
        }
    }
}




.toolTipContent {
    min-width: min-content;
    z-index: 1900;
    // border: 1px solid #d4d4d5;
    line-height: 1.4285em;
    max-width: 80vw;
    background: #fff;
    // padding: 0.833em 1em;
    font-weight: 400;
    font-style: normal;
    color: rgba(0,0,0,.87);
    // border: 1 1 1 0;
    font-size: 16px;
    // box-shadow: 0 2px 4px 0 #2224261f, 0 2px 10px 0 #22242626;
}