
.loading-overlay {
    z-index: 100000;
    // elevation: 100000;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 110%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2rem;
    
        &--stripePromise {
            height: 127%;
        }
}


.upidsPayment {
    &__heading {
        font-size: 1.2rem;
        margin-top: -1rem;
        margin-bottom: 1rem;
        font-weight: bold;
    }



    &__card {

        input,
        .StripeElement {
            display: block;
            margin: 1rem .1rem .3rem .1rem;
            max-width: 500px;
            padding: .8rem 1rem;
            font-size: 1em;
            font-family: "Source Code Pro", monospace;
            box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
                rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
            border: 0;
            outline: 0;
            background-color: #f8fcfd;
            border-radius: .5rem;
            border: 1px solid rgb(197, 207, 214);
        }

        input::placeholder {
            color: #aab7c4;
        }

        input:focus,
        .StripeElement--focus {
            box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
                rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
            -webkit-transition: all 150ms ease;
            transition: all 150ms ease;
        }

        .StripeElement.IdealBankElement,
        .StripeElement.FpxBankElement,
        .StripeElement.PaymentRequestButton {
            padding: 0;
        }

        .StripeElement.PaymentRequestButton {
            // height: 40px;
        }



        &__number {
            width: 20rem;
            margin: 1rem;
        }

        &__expiry {
            width: 7rem;
            margin: 1rem;
        }

        &__cvc {
            width: 5rem;
            margin: 1rem;
        }

    }

    &__sucess {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem;

        &__text {
            color: green;
            font-size: 1rem;
        }
        &__okbtn {
            padding-top: 1rem;
        }
        
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        &__text {
            margin-top: 1rem;
            margin-bottom: 0rem;
            font-size: .9rem;

            &__strip {
                margin-left: .2rem;
                margin-right: .2rem;
                color: blue;
                cursor: pointer;
            }
        }

        &__icon {
            background-image: url(../../images/stripe/stripe.svg);
            background-repeat: no-repeat;
            height: 2rem;
            width: 6rem;
        }
    }
}

.payment-input {
    background-color: rgb(248, 252, 253);
    border-radius: 7px;
    border: 1px solid rgb(197, 207, 214);
    font-size: 12px;
    padding: 6px;
    width: 100%;
}

.margin-top-10 {
    margin-top: 10px;
}