// .manufacturersidebar {
//   border-right: 1px solid #dfedf5;
// }

.manufacturersidebar {
  border-right: 1px solid #dfedf5;
  
  &.image-upload-card {
    border: 1px solid #c8d6e0;
    border-radius: 0.4375rem;
  }

  &.document-upload-card {
    border: 1px solid #c8d6e0;
    border-radius: 0.4375rem;
    padding: 0.625rem 0.625rem;
    margin-right: 15px;
  }

  &.image-info-title {
    width: 100%;
    
    font-size: 14px;
    font-weight: $font-weight-medium;
    color: #6489a0;
    text-align: center;
    margin-top: 0.3125rem;
  }

  &.crop-container {
    height: 31.25rem;
    margin-top: 0.625rem;

    .reactEasyCrop_Container {
      margin: 2.5rem 1.25rem;
    }

    .ReactCrop__image {
      height: 31.25rem;
    }
  }

  &.model-text {
    
    font-weight: $font-weight-semibold;
    color: #000000;
    font-size: 18px;
  }

  &.model-content {
    
    font-weight: $font-weight-normal;
    color: #000000;
    font-size: 14px;
  }

  .flip-card {
    background-color: transparent;
    width: 200px;
    height: 200px;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    img {
      border: 1px solid #f5f7f9;
      border-radius: 2px;
      width: 200px;
      height: 200px;
    }
  }

  .flip-card-front {
    background-color: transparent;
  }

  .flip-card-back {
    cursor: pointer;
    transform: rotateY(180deg);
  }

  &.documetn-title {
    
    font-weight: $font-weight-normal;
    color: #000000;
    font-size: 14px;
  }

  &.documetn-upload-title {
    
    font-weight: $font-weight-normal;
    color: #6489a0;
    font-size: 12px;
    cursor: pointer;
  }

  &.triangle {
    position: absolute;
    top: 0;
    right: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent $st-red transparent transparent;
    opacity: 0.9;
  }

  &.delete-icon {
    position: absolute;
    top: 2px;
    right: 0;
    color: #ffffff;
  }

  &.upload-button-container {
    position: absolute;
    top: calc(50% - 2.125rem);
    left: calc(50% - 2.125rem);
  }

  &.crop-loader-container {
    display: flex;
    justify-content: center;
    height: 31.25rem;
    width: 31.25rem;

    .spinner-border {
      width: 6.25rem;
      height: 6.25rem;
      border: 5px solid #cf2948;
      border-right-color: transparent;
      align-self: center;
    }
  }

  .other-images-container {
    margin: 20px !important;

    .flip-card {
      background-color: transparent;
      width: 150px;
      height: 150px;
      perspective: 1000px;
    }

    .flip-card-inner {
      border-radius: 7px;
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }

    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      img {
        border: 1px solid #f5f7f9;
        border-radius: 2px;
        width: 9.375rem;
        height: 9.375rem;
      }
    }

    .flip-card-front {
      background-color: transparent;
      border-radius: 7px;
    }

    .flip-card-back {
      border-radius: 7px;
      transform: rotateY(180deg);
    }
  }

  .mediaSection {
    right: 0px;
  }

  @media (max-width: 576px) {

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      // width: 100%;
      // height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      img {
        border: 1px solid #f5f7f9;
        border-radius: 2px;
        width: 11rem;
        height: 11rem;
      }
    }

    .mediaSection {
      right: 20px;
    }
  }

  @media (max-width: 768px) {
    display: grid;
    justify-content: center;
  }

}

.main-image-div {
  display: flex;
  background-color: #dfedf5;
  border-radius: 12px;
  opacity: 0.95;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: center;
  max-width: 356px;
  max-height: 356px;
  padding: 50px;
  align-self: center;

  @media only screen and (max-width: 1400px) {
    padding: 30px;
  }
}

.imgTag {
  width: 100%;
  border-radius: 31px;
  max-width: 256px;
  align-self: center;
}

.hrTag {
  margin-top: 10px;
}

.title {
  display: block;
  color: #131e27;
  opacity: 1;
  font-weight: bold;
  font-size: 24px;
  
  text-align: center;
}

.subtitle {
  display: block;
  color: #293f48;
  font-weight: 600;
  font-size: 14px;
  
  margin-top: 16px;
  text-align: center;
}