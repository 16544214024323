/* Badges */
//Badge vriations
@import "../mixins/badges";


.badge {
  border-radius: .125rem;
  font-size: 11px;
  font-weight: initial;
  line-height: 1;
  padding: .375rem .5625rem;
  font-weight: $font-weight-medium;
  &.badge-pill {
    border-radius: 10rem;
  }
}


/*Badge variations*/
@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variations($value);
  }
}

/*Badge outlined variations*/
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variations($value);
  }
}

.blue-badge{
  background-color: #E3F2FD !important;
  margin : .1875rem !important;
}