.modalDropdown {
    width: 100% !important;
}

.modalDatepicker {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--bs-gutter-y);

    &__label {
        display: block;
        margin-bottom: 7px;
        color: rgb(100, 137, 160);
        text-transform: uppercase;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
}

.tableHead {
    background: rgb(209, 51, 76);
    color:white;
    font-weight: 600;
    font-size: 16px;
}

