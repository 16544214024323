.st-divider{
    width: 100%;
    height: 1px;
    z-index: 100;
    background-color: #6489A0;

    &.divider-thin{
        background-color: #6489A0;
        opacity: 0.16;
    }
}