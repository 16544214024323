.dashboard-main-div {
  display: flex;
  flex-direction: column;
  background-color: #EFF8FD;
  height: 100%;
  .subdiv-1 {
    height: 76px;
    display: flex;
    .dashboard-span-text {
      flex: 1;
      font-size: 32px;
      font-weight: bold;
      align-self: center;
      margin-left: 32px;
    }
    .rightside-div {
      display: flex;
      flex: 1;
      align-self: right;
      justify-content: flex-end;
      // margin-right: 34px;
    }
    .rightside-div-dashboard {
      align-self: right;
      justify-content: flex-end;
    }
  }
  .subdiv-2 {
    display: flex;
    flex-direction: row;
    // justify-content: space-around;
  }
  .subdiv-3 {
    display: flex;
    margin-top: 20px;
  }
  .top-3-products-div
  {
    margin-top: 20px;
  }
}

.product_card img {
  object-fit: contain;
}