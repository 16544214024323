.error-string{
    
    color:#ff6060;
    font-size: 12px;
}
.tab-section-info{
    
    font-size: 14px;
    font-weight: $font-weight-semibold;
    color : #253C4B;
}
.passive-message{
    
    font-size: 12px;
    color: #96A5AF;
}
.warningsDesc{
    color: "#96a5af" !important;
    font-size: "12px" !important;
    
    margin-left: 15px ;
}
.warningsTitle{
    font-weight: 400;
    font-size:14px;
}