.inputDateTimePicker {
    margin-top: .6rem;
    margin-bottom: .6rem;

    &__label {
        max-width: 100%;
        display: flex;

        label {
            display: block;
            margin-bottom: .5rem;
            color: #6489a0;
            text-transform: uppercase;
        }
    }

    &__inputwrapper {

        div:has(> input){
            height: 40px;
            background-color: #f8fcfd;
            border-radius: 7px;
            border-color: #ededed;
            font-size: 12px;
            width: 100%;
            color: #253c4b;
        }

        &__input {
       
        }

    }

}