.users {
  &.main-div {
  }
}

.add-user {
  &.main-container {
  }
  &.sidebar-container {
    // height: calc(100vh - 5.3125rem);
    border-right: 1px solid $st-sidebar-border;
  }
  &.form-container {
    margin-top: 3rem;
  }
  &.profile-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
  &.role-title {
    color: #6489a0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $font-weight-medium;
    margin-left: 1.125rem;
  }
  &.permission-card {
    background-color: #ebfdf1;
    border-radius: 0.4375rem;
    margin: 1.125rem;
    padding: 0.25rem;
  }

  &.role-subtitle {
    color: #253c4b;
    text-align: left;
    opacity: 1;
    font-size: 14px;
    font-weight: bold;
    
    margin: 0.625rem;
  }
  &.toogle-switch {
    margin-left: auto;
    margin-right: 0.3125rem;
    margin-top: 0.25rem;
  }
  &.toggle-switch-color {
    .MuiSwitch-colorSecondary.Mui-checked {
      color: limegreen !important;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: limegreen !important;
    }
  }
  &.description-text {
    color: #687e91;
    font-size: 12px;
    font-weight: $font-weight-regular;
    
    display: block;
    margin-left: 0.625rem;
    margin-bottom: 0.625rem;
    margin-top: 5px;
  }
  &.error-text {
    font-size: 14px;
    font-weight: $font-weight-regular;
    
    display: block;
    margin-left: 0.625rem;
    margin-bottom: 0px;
  }
  &.profile-name {
    color: #131e27;
    font-size: 24px;
    font-weight: $font-weight-semibold;
    
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 1.375rem;
  }
  &.usertype-dropdown {
    @media (min-width: 1300px) {
      margin-left: 40px;
    }
    // @media (min-width: 768px) {
    //   min-width: 100px !important;
    // }
  }
}
// .toggle-switch-color {
//   .MuiSwitch-sizeSmall .MuiSwitch-thumb {
//     color: limegreen !important;
//   }
// }
//style={{ textAlign: 'center', opacity: 1, color: '#131E27', fontWeight: 'bold', fontSize: 24, 
//, marginLeft: 50, display: 'flex', width: 200, justifyContent: 'center' }}
