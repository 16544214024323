.manufacturer{
    &.sidebar-container{
        border-right: 1px solid #DFEDF5;
        // height : calc(100vh - 5.3125rem);
        margin : 0;
        padding : 0;
        display: flex;
        flex-direction: column;
    }
    &.image-container{
        display: flex;
        flex-direction: column;
        width: '100%';
        height: 15.625rem;
        background-color: aliceblue;
        border-radius: .75rem;
        opacity: 0.5;
        justify-content: center;
        margin: auto;
        align-self: center;
    }
    &.manufaturer-image{
        display: block;
        width: 5.5rem;
        height: 5.5rem;
        background: transparent;
        margin: auto;
        align-self: center;
    }
    &.image-title{
        text-align: center;
        color: #687E91;
        font-weight: 800;
        margin-bottom: 2.5rem;
    }
    &.form-container{
        width: '100%';
        margin-top: 2.5rem;
    }
}
// .btn-primary {
//     color: #fff !important;
//     background-color: #cf2948 !important;
//     border-color: #cf2948 !important;
// }
//style={{ width: '100%', marginTop: 40 }}
