@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "./stTextInputWithLabel.scss";
@import "./textInputWithLabel.scss";
@import "./textAreaWithLabel.scss";



$primary: #cf2948;
$accent: #253c4b;


.CircularProgressbar-path {
  stroke: $primary;
}

.CircularProgressbar-trail {
  stroke: gray;
}

.CircularProgressbar-text {
  fill: yellow;
}

.CircularProgressbar-background {
  fill: green;
}

.st-primary-bg {
  background-color: $primary;
}

.st-primary-border {
  border-color: $primary;
}

.cb-secondary.form-check-input:checked {
  background-color: $secondary;
  border-color: $secondary;
}

.cb-accent.form-check-input:checked {
  background-color: $accent;
  border-color: $accent;
}

.cb-accent.form-check-input[type="checkbox"]:indeterminate {
  background-color: $accent;
  border-color: $accent;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(1.25rem - 1px);
  border-top-right-radius: calc(1.25rem - 1px);
}

.card-text {
  padding: .375rem;
  
  font-size: 12px;
  font-weight: 600;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: white;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1.25rem;
}

.icon-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon-button-text {
    display: block;
    font-size: 14px;
    
    font-weight: bold;
    margin-bottom: 0px;
    margin-left: 8px;
    text-transform: uppercase;
  }
}

// spinner styles
.loader {
  height: 100vh; //vh - viewport height
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -100px;

  .image {
    vertical-align: middle;
    -webkit-animation: none;
  }

  .box {
    vertical-align: middle;
  }
}

.margin-loader {
  margin-left: 400px;
}

.badge {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: .3125rem;
  background-color: #e8e8e8;
  background-image: none;
  padding: .5833em .833em;
  color: rgba(0, 0, 0, .6);
  text-transform: none;
  font-weight: 600;
  font-size: 13px;
  border: 0 solid transparent;
  border-radius: .28571429rem;
  transition: background .1s ease;

  &.blue-badge {
    background-color: #E3F2FD !important;
  }
}

// .form-check-input:checked[type="checkbox"] {
//   background-image: url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFile%3ACheck_mark_9x9.svg&psig=AOvVaw0Kr5TN7hdnXlSswVdLJDyW&ust=1634804148616000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCLi49JbG2PMCFQAAAAAdAAAAABAD");
// }