.inputSelectDropdown {
    margin-top: .6rem;
    margin-bottom: .6rem;

    &__label {
        max-width: 100%;
        display: flex;

        label {
            display: block;
            margin-bottom: .5rem;
            color: #6489a0;
            text-transform: uppercase;
        }
    }




    &__inputwrapper {
        &__input {
            background-color: #f8fcfd !important;
            border-radius: 7px !important;
            border: 1px solid #ededed !important;
            height: 40px !important;
            font-size: 12px !important;
            padding: 16px !important;
            width: 100% !important;
            color: #253c4b !important;
            outline: none !important;
        }

        // &__input:read-only {
        //     background-color: #e9ecef !important;
        // }
    }
}

// .stDropdownButton {
//     background-color: #F8FCFD;
//     border-radius: 7px;
//     height: 40px;
//     padding: 12px;
//     width: 100%;
//     color: #253C4B;
//     outline: none;
//     font-size: 1em;
// }