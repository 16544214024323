.product-profile {

  &-navbar {
    border-bottom: 1px solid rgba(34, 36, 38, .15);
    margin-bottom: 1rem;
    padding: 0.5rem;

    .nav-link {
      color: rgba(0, 0, 0, 0.87);
      font-size: 1rem;
      font-weight: 600;

      &[data-tabselect="true"] {
        color: #cf2948;
        border-bottom: 3.5px solid #cf2948;
      }
    }

    .dropdown-item {
      color: rgba(0, 0, 0, 0.87);
      font-size: 1rem;
      font-weight: 600;

      &[data-tabselect="true"] {
        color: #cf2948;
        border-bottom: 1px solid #cf2948;
      }
    }
  }

  &--content {
    border-style: 'none';
    box-shadow: 'none';
    background-color: '#ffffff';
    margin: 1rem;
  }

  &.detail-tab {
    padding-top: 1rem;
    border-left: 1px solid #dfedf5;
    overflow-y: scroll;
  }

  &.passive-message {
    
    font-size: 12px;
    color: #96a5af;
  }

  &.normal-field-container {
    margin-bottom: 1rem;
  }

  &.multilang-field-container {
    margin-bottom: 1rem;
  }

  &.lang-text-span {
    margin-top: 12px;
    color: #131e27;
    font-weight: bold;
    font-size: 13px;
    opacity: 1;
    
  }

  &.tab-container {
    margin-left: 0.1333rem;
    width: "100%";
  }

  &.tab-pane-no-shadow {
    border-style: none;
    box-shadow: none;
  }

  &.additive-subsection {
    display: flex;
    margin-top: 0.625rem;
    flex-wrap: wrap;
  }

  &.allergen-subsection {
    display: flex;
    margin-top: 0.1333rem;
    flex-wrap: wrap;
    padding: 0.04rem;
  }

  &.edit-input {
    height: 1.75rem;
    width: 7.8125rem;
    margin-left: 0.3125rem;
    margin-top: 0.3125rem;
  }

  &.add-allergen-text {
    
    color: #23333e;
    font-size: 12px;
    font-weight: $font-weight-medium;
    cursor: pointer;
    align-self: center;
  }

  &.add-icon {
    margin-top: 0px;
    margin-right: 0.3125rem;
    align-self: center;
    cursor: pointer;
  }

  &.model-text {
    
    font-weight: $font-weight-semibold;
    color: #000000;
    font-size: 18px;
  }

  &.model-content {
    
    font-weight: $font-weight-normal;
    color: #000000;
    font-size: 14px;
  }

  &.category-label {
    
    font-size: 12px;
    font-weight: $font-weight-medium;
    width: 12.5rem;
    color: #6489a0;
  }

  &.add-nutrient-text {
    
    font-size: 12px;
    font-weight: $font-weight-normal;
    cursor: pointer;
    color: #96a5af;
    align-self: center;
  }

  &.upids-form-container {
    border-left: 1px solid #dfedf5;
    display: flex;
    flex-direction: row;
    padding: 1.875rem;
  }

  &.upids-view-container {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
  }

  &.feedback-card {
    border: 1px solid #deeaf2;
    border-radius: 0.625rem;
    height: 6.25rem;
    padding: 0.875rem;
    font-size: 14px;
    
    margin-bottom: 0.375rem;
    cursor: pointer;

    &.selected {
      background-color: #f8fcfd;
      border: 1px solid #dfedf5;
    }

    .email-text {
      color: #354052;
      font-weight: $font-weight-semibold;
    }

    .feedback-text {
      color: #6489a0;
      font-weight: $font-weight-normal;
    }
  }

  &.feedback-count {
    font-size: 14px;
    
    color: #354052;
  }

  .feedbacktab {
    &__graph {
      margin-top: 3rem;
      &__date {
        display: flex;
        margin-left: 1rem;
        margin-right: 1rem;

        .react-datepicker-wrapper {
          margin-left: 0.3rem;
          margin-right: 2rem;

          .react-datepicker__input-container input {
            width: 7rem;
          }
        }
      }
    }
  }

  // &.image-upload-card {
  //   border: 1px solid #c8d6e0;
  //   border-radius: 0.4375rem;
  // }
  &.document-upload-card {
    border: 1px solid #c8d6e0;
    border-radius: 0.4375rem;
    padding: 0.625rem 0.625rem;
    margin-right: 15px;
  }

  // &.image-info-title {
  //   width: 100%;
  //   
  //   font-size: 14px;
  //   font-weight: $font-weight-medium;
  //   color: #6489a0;
  //   text-align: center;
  //   margin-top: 0.3125rem;
  // }
  // &.crop-container {
  //   height: 31.25rem;
  //   margin-top: 0.625rem;
  //   .reactEasyCrop_Container {
  //     margin: 2.5rem 1.25rem;
  //   }
  //   .ReactCrop__image {
  //     height: 31.25rem;
  //   }
  // }
  // .flip-card {
  //   background-color: transparent;
  //   width: 200px;
  //   height: 200px;
  //   perspective: 1000px;
  // }

  // .flip-card-inner {
  //   position: relative;
  //   width: 100%;
  //   height: 100%;
  //   text-align: center;
  //   transition: transform 0.6s;
  //   transform-style: preserve-3d;
  // }

  // .flip-card:hover .flip-card-inner {
  //   transform: rotateY(180deg);
  // }

  // .flip-card-front,
  // .flip-card-back {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   -webkit-backface-visibility: hidden;
  //   backface-visibility: hidden;
  //   img {
  //     border: 1px solid #f5f7f9;
  //     border-radius: 2px;
  //     width: 200px;
  //     height: 200px;
  //   }
  // }

  // .flip-card-front {
  //   background-color: transparent;
  // }

  // .flip-card-back {
  //   cursor: pointer;
  //   transform: rotateY(180deg);
  // }

  &.documetn-title {
    
    font-weight: $font-weight-normal;
    color: #000000;
    font-size: 14px;
  }

  &.documetn-upload-title {
    
    font-weight: $font-weight-normal;
    color: #6489a0;
    font-size: 12px;
    cursor: pointer;
  }

  // &.triangle {
  //   position: absolute;
  //   top: 0;
  //   right: -2px;
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 0 50px 50px 0;
  //   border-color: transparent $st-red transparent transparent;
  //   opacity: 0.9;
  // }
  // &.delete-icon {
  //   position: absolute;
  //   top: 2px;
  //   right: 0;
  //   color: #ffffff;
  // }
  // &.upload-button-container {
  //   position: absolute;
  //   top: calc(50% - 2.125rem);
  //   left: calc(50% - 2.125rem);
  // }
  // &.crop-loader-container {
  //   display: flex;
  //   justify-content: center;
  //   height: 31.25rem;
  //   width: 31.25rem;
  //   .spinner-border {
  //     width: 6.25rem;
  //     height: 6.25rem;
  //     border: 5px solid #cf2948;
  //     border-right-color: transparent;
  //     align-self: center;
  //   }
  // }

  // .other-images-container {
  //   .flip-card {
  //     background-color: transparent;
  //     width: 150px;
  //     height: 150px;
  //     perspective: 1000px;
  //   }

  //   .flip-card-inner {
  //     border-radius: 7px;
  //     position: relative;
  //     width: 100%;
  //     height: 100%;
  //     text-align: center;
  //     transition: transform 0.6s;
  //     transform-style: preserve-3d;
  //   }

  //   .flip-card:hover .flip-card-inner {
  //     transform: rotateY(180deg);
  //   }

  //   .flip-card-front,
  //   .flip-card-back {
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     -webkit-backface-visibility: hidden;
  //     backface-visibility: hidden;
  //     img {
  //       border: 1px solid #f5f7f9;
  //       border-radius: 2px;
  //       width: 9.375rem;
  //       height: 9.375rem;
  //     }
  //   }

  //   .flip-card-front {
  //     background-color: transparent;
  //     border-radius: 7px;
  //   }

  //   .flip-card-back {
  //     border-radius: 7px;
  //     transform: rotateY(180deg);
  //   }
  // }

  &.ai-suggestions-show {
    z-index: 2;
    height: 100%;
    width: 300px;
    position: fixed;
    right: 0px;
    transition: right 0.50s ease-in;
    box-shadow: 0px 1px 20px rgb(238, 237, 237);
    background-color: white;

  }

  &.ai-suggestions-hide {
    height: 100%;
    width: 300px;
    position: fixed;
    right: -300px;
    transition: right 0.50s ease-out;
    // box-shadow: 0px 1px 20px;
    background-color: white;

  }

  &.eyeIcon {
    position: absolute;
    cursor: pointer;
    right: 1.5rem;
    top: 2.2rem;
    color: #6489A0
  }

}

.customModalClass{
  width: 750px !important;
  max-width: 900px !important;
}
.customDropdown{
  border:'1px solid #c5cfd6';
   border-radius:7px;
   padding:8px;
}

.categoryDropdowns {
  width: 1100px;
}

.feedbackDisplayCard {
  overflow-y: scroll;
  // height: 300px;
  border-top: 1px solid rgb(223, 237, 245);
  padding-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.versioningTabDiv {
  display: flex;
}

.versioningCard {
  height: 31.25rem;
  width: 30%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px !important;
  }
}

.versioningTableCard {
  width: 70%;
}

@media (max-width: 576px) {
  .categoryDropdowns {
    width: auto;
  }

  .feedbackDisplayCard {
    overflow-y: scroll;
    height: 300px;
    border-top: 1px solid rgb(223, 237, 245);
    padding-top: 22px;
  }
}


.product_feedback_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &_reply_input {
    
    background-color: rgb(248, 252, 253);
    border-radius: 7px;
    border: 1px solid rgb(197, 207, 214);
    height: 40px;
    font-size: 12px;
    padding: 16px;
    width: 100%;
    color: rgb(37, 60, 75);
    outline: none;
  }

  &_reply_button {
    cursor: pointer;
    font-size: 26px;
    color: #6489a0;

    &:hover {
      font-size: 28px;
    }
  }
}

.colorpickerInput {
  background-color: "#F8FCFD";
  border-radius: "7px";
  height: "40px";
  font-size: "12px";
  padding: "16px";
  width: "100%";
  color: "#253C4B";
  cursor: pointer;
}

.emailBodyTextarea {
  outline: #DBE7EF;
}

.jodit-react-container {
  background-color: #F8FCFD !important;
  border-radius: 7px !important;
}

.feedbackTableText:hover {
  text-decoration: underline;
}


@media (max-width: 576px) {
  .versioningTabDiv {
    display: block;
  }

  .versioningCard {
    width: 100%;
  }

  .versioningTableCard {
    width: 100%;
  }
}

@media (min-width: 1600px) {
  .versioningTabDiv {
    display: flex;
  }

  .versioningCard {
    width: 20%;
  }

  .versioningTableCard {
    width: 80%;
  }

}